.vertical-items{
  &-info{
    border-bottom: 1px solid #DDDDDD;
    padding-bottom: 10px;
    margin-bottom: 10px;
    &:nth-last-child(1){
      padding-bottom: 0px;
      margin-bottom: 0px;
      border-bottom: 0px;
    }
    &-desc{
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: Title Semibold;
      color: #19191C;
      &-title{
        width: 400px;
        font-weight: 600;
        &-line-through{
          text-decoration: line-through;
        }
      }
      &-qty{
        padding-left: 5px;
      }
      &-total{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        &-voided{
          .total-voided-label{
            width: 50px;
            padding: 2px 5px;
            background-color: #f5f5f5;
            border-radius: 5px;
          }
        }
      }
    }
    &-modifiers, &-notes{
      font-size: 16px;
      color: #55565E;
      width: 535px;
      // margin-bottom: 10px;
    }
    &-discount{
      display: flex;
      justify-content: space-between;
      color: #E10013;
      font-size: 16px;
    }
    &-send-time{
      line-height: 32px;
      color: #55565e;
      font-size: 16px;
      text-align: right;
    }
  }

  &-check-discount-wrap{
    margin-top: 3px;
  }
}