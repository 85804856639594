.drag-select{
  &-slide{
    max-width: 400px;
    max-height: 400px;
    overflow: hidden;
    overflow-y: auto;
  }
  &-box{
    max-width: 400px;
    overflow: hidden;
    cursor: pointer;
    .iconfont{
      font-size: 24px !important;
    }
  }
  .drag-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 20px;
  }
}