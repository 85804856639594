.modal-add-modifier-group {
  .ant-modal .ant-modal-body {
    height: 70vh !important;
    overflow: auto !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .ant-modal-content {
    background-color: #f1f1f1;
    padding: 20px 10px;
  }

  .ant-modal-title {
    background-color: #f1f1f1;
    font-size: 24px;
    padding: 0 15px;
  }

  .ant-modal .ant-modal-footer {
    padding: 20px 10px 0 0 !important;
  }
}