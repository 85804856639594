.dining-options-content{
  .title{
      font-size: 18px;
      font-weight: 500;
  }

  &-tabpane{
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 12px 0;
  }

  .ant-table-content{
      .no-border-bottom > td {
          border-bottom: none;
      }
  }
}   