@import "@/styles/index";
.ordersReport {
  .ant-tabs-content-holder {
    display: none;
  }

  .main-title {
    display: flex;
    align-items: center;
    height: 57px;
    font-size: 18px;
    border-bottom: 1px solid #F0F0F0;
  }

  .filterWrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border-radius: 4px;

    .ant-form {
      gap: 10px 0;
    }
    .right-wrap {
      display: flex;
      align-items: center;

      .icon-table {
        color: $primaryColor
      }
    }
  }

  .tableWrap {
    .colOrderStatus {
      .ant-tag {
        margin-left: 5px;
      }
    }

    .btnDetail {
      span {
        margin-inline-start: 0;
      }
    }
  }
}

.diningOptionSelectPopup {
  .ant-select-tree-checkbox {
    margin-top: 8px !important;
  }

  .ant-select-tree-node-content-wrapper {
    padding: 3px 0 !important;
  }

  .ant-select-tree-switcher {
    width: 15px !important;
  }
}
