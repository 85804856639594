@import "../index.scss";

.menus-general-info {
  @include menus-card-style;
  &-color-filter{
    margin-top: 10px;
    .ant-form-item-control-input{
      min-height: 0px !important;
    }
  }
}