.fee-report-summary-content {
  display: flex;
  gap: 0 64px;
  margin-top: 8px;
  padding: 24px;
  background: white;
  border-radius: 6px;
  overflow-x: auto;

  .item-wrap {
    .top {
      display: flex;
      align-items: center;
      height: 24px;

      .title {
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
      }

      .tip-icon {
        margin-left: 10px;
        font-size: 20px;
        color: #55565E;
      }
    }

    .amount {
      margin-top: 6px;
      font-size: 24px;
      font-weight: 500;

      &.bold {
        font-weight: 700;
      }
    }

    & + .item-wrap {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        height: 28px;
        width: 1px;
        background: #DDD;
        left: -32px;
        transform: translateY(50%);
      }
    }
  }
}