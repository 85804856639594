.account-container-form{
    background: rgb(255 255 255);
    border-radius: 8px;
    padding: 32px;

    .form-title{
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: rgb(61 61 61);
    }

    .material-form{
      margin-top: 12px;

      &-item{
        padding-bottom: 18px;
        vertical-align: top;
      }

      &-container{
        display: flex;

        .form-container-label,
        .form-container-content{
          display: inline-flex;
          align-items: baseline;

        }

        .form-container-label{
          color: #222;
          font-size: 14px;
          line-height: 1.4286;
          min-width: 100px;
          max-width: 180px;
        }

        .form-container-content{
          color: #222;
          font-size: 14px;
          line-height: 1.4286;
          word-break: break-all;
          overflow-wrap: break-word;
        }
      }
    }
  }