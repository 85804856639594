.check-and-guest-info{
  .check-and-guest-info-wrap{
    &-sort-box{
      display: flex;
    }
    &-sort-item{
      flex: 1;
      text-align: right;
      &:nth-child(1){
        width: 280px;
        text-align: left;
      }
    }
  }
}