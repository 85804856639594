.categories {
  .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: rgb(34 34 34);
  }

  .filter-wrap {
    margin-top: 16px;

    .antd-form-item-wrapper {
      margin-right: 16px;
    }
  }

  .table-wrap {
    margin-top: 20px;
  }
}