.create-modifier-setting{
  margin: 15px;
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  .ant-form-item{
    margin-bottom: 0px;
  }
  .ant-form-item-label{
    padding: 0px;
  }
  &-title{
    font-family: Nunito Sans;
    font-size: 18px;
    line-height: 26px;
  }
  &-mt{
    margin-top: 14px;
  }
}