.tip-area{
  margin-top: 16px;
  &-item{
    display: flex;
    align-items: flex-start;
    margin-top: 8px;
    &:nth-last-child(1){
      margin-top: 35px;
    }
    &-check{
      position: absolute;
    }
    &-square{
      width: 18px;
      height: 18px;
      border: 2px solid #252525;
      margin-right: 15px;
      border-radius: 3px;
    }
    &-bracket {
      width: 18px;
      height: 18px;
      margin-right: 15px;
      font-weight: 600;
    }
    &-custom-tips{
      display: flex;
      width: 100%;
      height: 50px;
      justify-content: flex-start;
      &-block{
        width: 150px;
        &-top-line{
          height: 20px;
          border-bottom: 1px solid #252525;
          margin-bottom: 10px;
        }
        &:nth-child(n + 2){
          margin-left: 32px;
        }
      }
    }
  }
}