@import '../mixin.scss';
.print-info{
  @include reportSectionGap{
    &-item{
      display: flex;
      justify-content: space-between;
      &-title{
        width: 105px;
      }
      &-value{
        flex: 1;
      }
    }
  }
}