@mixin menus-card-style {
  margin: 15px;
  padding: 24px, 16px, 24px, 16px;
  background-color: #fff;
  border-radius: 5px;
}

@mixin menus-background-style {
  min-height: 100%;
  background-color: #f1f1f1;
  padding-bottom: 5px;
}

@mixin menus-card {
  .ant-card-head {
    min-height: 46px;
    border-bottom: 0;
    padding: 16px 15px 0;
  }

  .ant-card-body {
    padding: 0 24px 16px;
  }

  .ant-card-bordered {
    border: 0px;
  }
}

@mixin menus-form {
  .ant-form-item {
    margin-bottom: 5px;
  }
}
@mixin card-form {
  .ant-form-item-label, .ant-form-item{
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
// 一行五个元素
@mixin menus-five-items-per-row {
  .ant-form-item .ant-form-item-control-input-content {
    max-width: 80%;
  }
  .ant-checkbox-group-item {
    width: calc(90% / 5);
    margin-bottom: 15px;
  }
  .ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
}
// usage卡片
@mixin usage-card{
  .menu-edit-modifier-card{
    background-color: #ffffff;
    margin-top: 24px;
    padding: 24px 16px;
    border-radius: 8px;
    &-item{
      .pointer{
        margin-right: 8px;
      }
    }
  }
}