.guest-display{
    .row-box{
        margin: 40px 0;
    }

    .top{
        .title{
            font-size: 18px;
            font-weight: 500;
            line-height: 32px;
            color: #343434;
        }
    }

    .title-2 {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        color: rgb(61 61 61);
    }

    .text{
        padding-right: 20px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0;
        color: rgb(102 102 102);
    }

    .img{
        margin-bottom: 20px;
    }

    .bg-preview{
        position: relative;
        width: 366px;
        height: 236px;
        background-position: center center;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .pre-img{
            position: absolute;
            width: 340px;
            height: 210px;
            top: 13px;
            left: 13px;
            z-index: 5;

            &.order-image{
                left: 183px;
                width: 170px;
                height: 210px;
            }
        }

        .txt{
            display: inline-block;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            font-size: 12px;
            width: 150px;
            text-align: center;
        }
    }
}