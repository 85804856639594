@import "../index.scss";
.menus-usage {
  @include menus-card-style;
  .usage-item {
    display: flex;
    align-items: center;
    margin-top: 5px;
    &-content::before {
      content: '';
      display: inline-block;
      width: 8px; /* 圆点的直径 */
      height: 8px; /* 圆点的直径 */
      background-color: #00CE46; /* 圆点的颜色 */
      border-radius: 50%; /* 使得四角变为圆形 */
      margin-right: 5px; /* 与文本保持一定距离 */
    }
    
  }
  .usage-wrap{
    margin-right: 8px;
  }
}