@import "/src/styles/mixin";

.job-create-content{
  background: rgb(249 249 249);
  padding: 24px;

  &-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-wrap{
    margin-top: 24px;
    padding: 16px;
    background: rgb(255 255 255);
    border-radius: 8px;
  }

  &-form{
    margin-top: 12px;
    font-size: 14px;

    .ant-form-item-label{
      width: 150px;
      text-align: left;
      flex: none;
      max-width: none;
    }

    .ant-form-item-control{
      width: 550px;
      flex: none;
      max-width: none;

      .access-code-addon-after{
        cursor: pointer;

        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  &-title{
    font-size: 16px;
    font-weight: 500;
  }
  &-card{
    padding-top: 24px;
    .job-create-checkbox-group{
      line-height: 30px;
      background: #F9F9F9;
      border-radius: 8px;
      padding: 16px;
      margin-top: 12px;
    }
  }
}