.pos-monitor-content{
    .title-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 57px;
        font-size: 18px;
        background: white;
        border-bottom: 1px solid #f0f0f0;

        .title {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    .table-wrap {
        margin-top: 16px;
    }
    &-card{
        margin-bottom: 16px;
        background: #ffffff;
        padding: 16px;
        border-radius: 8px;
        &-title{
            margin-bottom: 8px;
            color: #252525;
        }
        .ant-form-item{
            margin-bottom: 16px;
            &:nth-last-child(1){
                margin-bottom: 0px;
            }
        }
    }
    .title{
        font-size: 18px;
    }
}