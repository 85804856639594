.columns {
  position: relative;
  display: inline-block;
  line-height: 20px;
  .btn{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
    transition: 0.3s;
    &.ant-popover-open, &.ant-popover-open:hover{
      background-color: #EEF0FF;
    }
    .txt{
      padding-left: 5px;
    }
  }
}
.slider{
  .ant-checkbox-wrapper{
    width: 100%;
    font-size: 18px;
    border-radius: 4px;
    transition: 0.3s;
    padding: 2px 5px;
    .ant-checkbox{
      margin-top: 6px;
      align-self: flex-start;
    }
    &:hover{
      background-color: #EEF0FF;
    }
  }
}
.selectCheck{
  flex-direction: column!important;
}