.serviceChargeEdit {
  padding: 24px;
  height: 100vh;
  background: #F5F5F5;

  .headerWrap {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 24px;
      color: rgb(61, 61, 61);
      font-weight: 500;
    }

    .actions {
      display: flex;
      gap: 12px;
    }
  }

  .formWrap {
    margin-top: 16px;

    .cardBg {
      padding: 16px;
      background: white;
      border-radius: 8px;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-form-item-control-input {
      min-height: 22px;
    }

    .required {
      color: red;
      margin-left: 2px;
    }

    .taxRateRadioGroup {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      & > * {
        display: flex;
        align-items: center;
        width: 22%;
      }
    }
  }
}