.square{
    width: 44px;
    height: 44px;
    background: #F4F4F4;
    border: 2px solid rgb(0 0 0 / 87%);
    cursor: crosshair;
}

.circle{
    width: 44px;
    height: 44px;
    background: #F4F4F4;
    border: 2px solid rgb(0 0 0 / 87%);
    border-radius: 50%; // 将边角半径设置为50%来创建圆形
    cursor: crosshair;
    margin-right: 32px;
}

.wall{
    width: 170px;
    height: 20px;
    background: #222;;
    cursor: crosshair;
}

.shape-name{
    height: 100%;
    line-height: 100%;
    white-space: nowrap;      /* 确保文本在一行内显示，不换行 */
    overflow: hidden;
}