.payment-methods-edit {
  padding: 24px;
  height: 100vh;

  .header-wrap {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 24px;
      color: rgb(61, 61, 61);
      font-weight: 500;
    }

    .actions {
      display: flex;
      gap: 12px;
    }
  }

  .form-wrap {
    margin-top: 16px;

    .required {
      color: red;
      margin-left: 2px;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-form-item-control-input {
      min-height: 22px;
    }
  }
}