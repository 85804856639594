.edit-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 10px;
    border-radius: 4px;
    transition: 0.3s;
    &.ant-popover-open, &.ant-popover-open:hover, .ant-btn-link:hover{
        background-color: #EEF0FF !important;
    }
    .txt{
        font-size: 18px;
        padding-left: 5px;
    }
}
.ul-edit{
    font-size: 18px;
    .edit-item{
        padding: 2px 5px;
        transition: 0.3s;
        border-radius: 4px;
        min-width: 100px;
        max-width: 500px;
        overflow: hidden;
        cursor: pointer;
        text-overflow: ellipsis;
        &:hover{
            background-color: #EEF0FF;
        }
    }
}