.net-sales-summary-legend-wrap {
  margin-top: 12px;
  display: flex;
  align-items: center;

  .blue-point {
    display: inline-block;
    margin-right: 8px;
    width: 12px;
    height: 12px;
    background: #2563EB;
    border-radius: 50%;
  }

  .gray-point {
    display: inline-block;
    margin-left: 48px;
    margin-right: 8px;
    width: 12px;
    height: 12px;
    background: #BEBEBE;
    border-radius: 50%;
  }
}