@import "/src/styles/mixin";

.successFul-page{
    width: 350px;
    text-align: center; 

    &-message{
        img{
            height: 60px;
            margin: 0 auto;
            display: block;
        }

        &-tips{
            font-size: 26px;
            font-weight: 600;
            line-height: 36px;
            color: rgb(34 34 34);
            display: inline-block;
            vertical-align: middle;
        }
    }  
}