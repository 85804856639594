.create-modifier-group-table{
  margin: 15px;
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  &-title{
    min-height: 46px;
    font-size: 16px;
    font-weight: 600;
  }
  .ant-table-cell{
    &::before{
      display: none;
    }
  }
}