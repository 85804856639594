.net-sales-summary-report {
  min-width: 900px;
  padding-bottom: 22px;
  background: #F5F5F5;

  .title-wrap {
    height: 57px;
    font-size: 18px;
    background: white;

    .title {
      height: 100%;
      margin: 0 22px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #DDDDDD;

    }
  }

  .content-wrap {
    padding: 0 22px;
  }

  .filter-wrap {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .filter-right-wrap {
      display: flex;
      gap: 0 10px;

      .ant-btn {
        border: 0;
      }
    }
  }
}