.ant-menu-submenu{
  z-index: 1051;
}
.cardContent {
  border-radius: 16px 0 0 16px !important;
  background-color: #fff;
}

/* .ant-layout-has-sider {
  background-color: #0D1F2D;
} */
.ant-layout-header  {
  border-radius: 16px 0 0;
}


.header-userName {
  display: flex;
  // flex-direction: row-reverse;
  padding: 0 16px;
}

.header-userName > span {
  margin: 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downOut {
  display: inline-block;
  width: 8px;
  height: 8px;
  border: 1px solid #222;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
  cursor: pointer;
  position: relative;
  top: -1px;
}

.middleLine {
  width: 1px;
  height: 20px;
  background: rgb(0 0 0 / 20%);
  position: relative;
  top: 8px;
  margin: 0 0 0 8px;
}

.menuIcon {
  display: inline-block;
  width: 20px;
  height: 20px;
  padding-right: 10px;
  position: relative;
  top: -2px;
}

.menuList {
  width: 240px;
  overflow: auto;
}

.menuListBox {
  width: 240px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px;

  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.iconfont{
  font-size: 20px !important;
}

.home-name {
  display: flex;
  align-items: center;
  padding-left: 10px;
  overflow: hidden;
  min-width: 200px;
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.MenuUnfoldOutlined{
  width: 50px;
  height: 40px;
  padding-left: 11px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}
.ant-modal-body{
  padding-bottom:16px!important;
  padding-top:16px!important;
}

.ant-row ,
.ant-row-top{
  margin-left: 0!important;
  margin-right: 0!important;
}

.menuListBox:hover {
  background-color: #f9f9f9;
}

.menuListBox1:hover {
  background-color: #f9f9f9;
}

.ant-menu-submenu-selected .ant-menu-submenu-title{
  background-color: rgb(255 255 255 / 7%);
}

.ant-menu-submenu-popup .ant-menu-vertical{
  .ant-menu-item {
    width: 100%;
    margin: 8px 0 ;
  }
}

.ant-menu.ant-menu-sub.ant-menu-vertical{
  padding: 4px 8px;
}
.main-tab-title {
  display: flex;
  font-size: 18px;

  &:after {
    content: ' ';
    margin: 0 32px 0 16px;
    display: block;
    height: 28px;
    width: 1px;
    background: #DDD;
  }
}
