
.preview-ui-text-line-new{
  /* 兼容性写法 */
  word-wrap: break-word;
  /* 标准写法 */
  overflow-wrap: break-word;
  // &-sm{
  //   font-size: 18px;
  //   line-height: 28px;
  // }
  // &-md{
  //   font-size: 22px;
  //   line-height: 32px;
  // }
  // &-lg{
  //   font-size: 28px;
  //   line-height: 36px;
  // }
  // &-bold{
  //   font-weight: 600;
  // }
  // &-mb{
  //   margin-bottom: 16px;
  // }
  // &-italic {
  //   font-style: 'italic';
  // }
  // &-normal {
  //   font-style: 'normal';
  // }
}