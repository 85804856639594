.login-page{
  min-height: 300px;
  position: relative;
  height: 100% !important;
  display: flex;
  // flex-flow: row wrap;
  align-items: center;

  &-banner{
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 4px;

    &-wrap{
      width: 100%;
      height: 100vh;
      background-size: cover;
      background-position: center top;
    }
  }

  &-login-wrap{
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 4px;
    padding-right: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255 255 255);
    height: 100vh;
  }
}