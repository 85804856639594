@import '../../../styles/index.scss';

.menus-builder{
  .null-box{
    padding-top: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-end;
    max-width: 1033px;
    margin: 0 auto;
    .modifier-group-null, .sub-menu-null{
      flex-grow: 0;
      flex-shrink: 0;
    }
    .modifier-group-null{
      width: 450px;
    }
    .sub-menu-null{
      width: 333px;
    }
    .text{
      margin: 20px auto;
      max-width: 450px;
      font-size: 18px;
      color: #252525;
      line-height: 22px;
      &.s2{
        max-width: 500px;
      }
    }
    .or{
      flex-grow: 0;
      flex-shrink: 0;
      margin: 250px auto 0;
    }
    .bgi{
      position: relative;
      margin: 0 auto;
      width: 170px;
      line-height: 170px;
      min-height: 152px;
      &.s2{
        height: 170px;
      }
      img{
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        margin: 0 auto;
        user-select: none;
        pointer-events: none;
      }
    }
    .modifier-group-null{
      position: relative;
      .tips{
        position: absolute;
        top: 0;
        right: 20%;
        height: 20px;
        width: 20px;
        color: #55565e;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .menu-select{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    height: 60px;
    margin-top: 10px;
    line-height: 60px;
    font-size: 18px;
    background-color: #F5F5F5;
    .drag-name{
      font-weight: bold;
    }
  }
  .dn{
    display: none;
  }
  .content-box{
    position: relative;
    overflow: hidden;
    .sub-menu-list{
      width: 500px;
      padding: 20px 40px 0 20px;
      border-right: 1px solid #ddd;
      overflow: hidden;
    }
    .item-list{
      padding: 20px 20px 0 40px;
      overflow: hidden;
    }
  }
  .op0{
    opacity: 0;
  }
  .list-box{
    overflow-y: auto;
    height: calc(100vh - 264px);
  }
}

.menu-item-list{
  .drag-list{
    .drag-item{
      padding: 10px 0 10px 10px;
    }
    .left-info{
      .iconfont{
        overflow: hidden;
        margin-right: 20px;
        width: 52px;
        height: 52px;
        text-align: center;
        line-height: 50px;
        border-radius: 8px;
        background-color: #ddd;
      }
    }
    .right-text{
      width: 95px;
    }
  }
}
.a-btn{
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  color: $primaryColor;
  .iconfont{
    margin-left: 5px;
  }
}
.popover-text{
  max-width: 360px;
  max-height: 110px;
  font-size: 18px;
  color: #000;
}
.existing-pop{
  .selected-name{
    margin: 10px 0;
  }
  .ant-tag{
    position: relative;
    overflow: hidden;
    max-width: 450px;
    text-overflow: ellipsis;
    padding-right: 20px;
    .anticon-close{
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}