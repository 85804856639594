.online-orders-wrap {
  .ant-tabs-top {
    overflow: inherit;

    .ant-tabs-content-holder {
      overflow: inherit;

      .ant-tabs-tabpane-active {
        overflow: inherit;
      }
    }
  }

  .main-title {
    display: flex;
    font-size: 18px;

    &:after {
      content: ' ';
      margin: 0 32px 0 16px;
      display: block;
      height: 28px;
      width: 1px;
      background: #DDD;
    }
  }
}