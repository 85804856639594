.content {
	display: flex; /* 使用Flexbox布局 */
}

.top-area{
	padding: 0 0 22px;
	display: flex;
	justify-content: space-between;
	max-width: 1200px;
}

.content-left {
	width: 236px; /* 左侧固定宽度 */
	background: #F4F4F4; /* 为了更清楚地显示布局，设置背景色 */
	border-right: 2px solid #D0D0D0;
	padding: 35px 28px;

	.shape-container{
		p{
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 17px;
		}

		.shape-content{
			display: flex;
		}
	}

	.line{
		margin: 22px;
		background: #F4F4F4;
		height: 2px;
	}
}

.content-right {
	// flex-grow: 1; /* 右侧宽度自适应，占据剩余空间 */
	background-color: #F4F4F4; /* 设置背景色 */
	.grid-container {
		width: calc(16px * 60); /* 计算整个网格的宽度 */
		height: calc(16px * 28); /* 计算整个网格的宽度 */
		background-color: white; /* 设置背景色为白色 */
		position: relative;
	}

	.grid-canvas{
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 2;
	}

	.grid-remind{
		width: 100%;
		height: 100%;
		position: absolute;
		z-index: 2;
		text-align: center;
		font-size: 20px;
		color: rgb(0 0 0 / 40%);
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.grid-bg{
		width: calc(16px * 60); /* 计算整个网格的宽度 */
		display: flex;
		flex-wrap: wrap; /* 允许小格子换行 */
		position: absolute;
		z-index: 1;
	}

	.cell{
		width: 16px;
    height: 16px;
    border: 1px solid #F4F4F4;
    box-sizing: border-box;
	}
}

.content-info{
	padding: 27px 24px;

	.top{
		display: flex;
		justify-content: space-between;

		.title{
			font-size: 16px;
			font-weight: 500;
			color: #222;
		}

		.delete{
			font-size: 18px;
			font-weight: 600;
			color: #FE5E10;
		}
	}

	.character-remind{
		font-size: 20px;
		font-weight: normal;
		line-height: 120px;
		text-align: center;
		height: 120px;
		color: rgb(0 0 0 / 40%);
	}

	.edit-area{
		display: flex;
		margin-top: 24px;
		width: 210px;

		.item{
			margin-right: 24px;

			.name{
				font-size: 14px;
				font-weight: 500;
				color: #222;
				margin-bottom: 6px;
			}

			.number{
				height: 50px;
				line-height: 50px;
				font-size: 20px;
				font-weight: 600;
				color: #222;
				text-align: center;
			}
		}
	}
}

.custom-modal{
	.ant-modal-content{
		background-color: rgb(34 34 34 / 80%);
		border-radius: 4px;
	}
}

.loading-box{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.loading-character{
	font-size: 20px;
	font-weight: 500;
	color: #FFF;
	padding-bottom: 10px;
}

.moveable-line,.moveable-control{
	background: #FF5400 !important;
}

.moveable-origin{
	visibility: hidden;
}

.delete-characher{
	display: inline-block;
	margin-left: 10px;
}

.edit-area .ant-input, .edit-area .ant-input-number-input{
	width: 210px !important;
	height: 50px !important;
	border-radius: 4px  !important;
}