.status-item{
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 16px;
    transition: 0.3s;
    &:hover{
        background-color: #EEF0FF;
    }
}
.point{
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 8px;
    &.active{
        background-color: #00CE46;
    }
    &.archived{
        background-color: #BEBEBE;
    }
}
.popover-btn {
    display: flex;
    width: 120px;
    justify-content: space-between;
}
.pointer{
    cursor: pointer;
}