.shop-list{
    width: 530px;
    text-align: center;

    &-logo{
        display: inline-block;

        img{
            display: block;
            width: 100%;
            margin-top: 19px;
        }
    }

    &-tips{
        text-align: center;
        margin-top: 20px;
        margin-bottom: 1em;
    }

    &-items{
        max-height: 520px;
        overflow-y: auto;
    }

    &-item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 0;
        color: rgb(34 34 34 / 88%);
        border-block-end: 1px solid rgb(5 5 5 / 6%);
        border-width: 0;
        text-align: left;

        &-wrap{
            padding: 16px 24px;
            border: 1px solid rgb(0 0 0 / 10%);
            border-radius: 4px;
            width: 530px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            cursor: pointer;
            list-style: none;

            .shop-list-item-info{
                .shop-item-title{
                    font-size: 18px;
                    line-height: 36px;
                    font-weight: 500;
                    color: rgb(34 34 34);
                }

                .shop-item-id{
                    margin-bottom: 1em;
                }
            }

            .shop-list-item-arrow{
                margin: auto 0 auto auto;
                font-size: 20px;
            }
        }
    }
}