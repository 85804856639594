.preview-ui-payment-status{
  position: relative;
  margin-top: 32px;
  &-dashed-line{
    border: none;
    border-bottom: 1px dashed #252525; // 设置边框为虚线
  }
  &-absolute{
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    .preview-ui-text-line, .preview-ui-text-line-new{
      background: #ffffff;
      padding: 0 10px;
    }
  }
}