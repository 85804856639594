.item-info{
  &-wrap{
    &-item{
      display: flex;
      &-id{
        width: 30px;
      }
      &-info{
        width: 359px;
      }
      &-price{
        flex: 1;
        text-align: right;
      }
    }
    &-narrow{
      margin-bottom: 0px;
    }
    &-mid{
      margin-bottom: 14px;
    }
    &-wide{
      margin-bottom: 18px;
    }
  }
  &-modifiers{
    margin-left: 30px;
    &-column{
      display: flex;
      &-id{
        width: 40px;
      }
      &-info{
        flex: 1;
      }
      &-price{
        width: 80px;
        text-align: right;
      }
      margin-bottom: 8px;
    }
  }
  &-comp{
    margin-left: 30px;
    margin-bottom: 8px;
    &-column{
      display: flex;
      justify-content: space-between;
      &-detail{
        width: 330px;
      }
      &-price{
        min-width: 80px;
        text-align: right;
      }
    }
  }
}