.net-sales-summary-chart-tooltip {
  .date-wrap {
    display: flex;
    align-items: center;
    color: #161616;

    .gray-point, .blue-point {
      display: inline-block;
      margin-right: 10px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }

    .gray-point {
      background: #BEBEBE;
    }

    .blue-point {
      background: #2463EB;
    }
  }

  .price-wrap {
    margin-top: 4px;
    color: #252525;
    font-size: 14px;
    font-weight: 400;

    span:nth-child(1) {
      margin-right: 20px;
    }
  }
}