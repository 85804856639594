
.nestedlist-list{
    &-items{
        margin: 0;
        padding: 0;
        list-style: none;
        &-btn{
            margin-left: 180px;
        }
    }
    &-item{
        border-width: 0px;
        padding: 8px 0;
        cursor: pointer;
        padding-right: 20px;
        color: #252525;
        &-title{
            padding-left: 8px;
            padding-right: 8px;
            &-icon{
                display: flex;
                align-items: center;
            }
        }
        &-active{
            background-color: #eef0fe;
            border-radius: 8px;
        }
        &-empty-text{
            padding: 16px;
            color: rgba(34, 34, 34, 0.25);
            font-size: 14px;
            text-align: center;
        }
    }
    &-subtitle{
        color: #252525;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-ml{
        margin-left: 16px;
    }
    &-min-height{
        height: 60vh;
        overflow-y: scroll;
    }
}