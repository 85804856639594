.resturant-info-form{
    .ant-radio-wrapper{
        margin-top: 25px;

        .ant-radio{
            align-self: flex-start;
            margin-top: 5px;
        }
    }

    &-manualDailyClear{
        display: block;
        font-size: 12px;
        color: #0006;
    }
}