.decoration-print-out {

  .decoration-container {
    display: flex;
    .config-container {
      width: 600px;
      margin-right: 20px;
    }

    .preview-container {
      width: 530px;
      padding: 32px 24px;
      border: 1px solid #BEBEBE;
    }
  }
}