.modalRoleRelateEmployeeList {
  .tableWrap {
    margin-top: 20px;

    .colActions {
      .btnDisconnect {
        display: flex;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}