.range-picker-date-popup {
  padding-top: 2px;

  .ant-picker-panel-container {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
  }

  .ant-picker-range-arrow {
    display: none !important;
  }

  .ant-picker {
    border-radius: 4px;
  }

  .ant-picker-panel-container {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15) !important;
  }

  .ant-picker-cell-today {
    .ant-picker-cell-inner:before {
      border: 0 !important;
    }
  }

  .ant-picker-cell-range-start {
    .ant-picker-cell-inner {
      color: white !important;
    }

    .ant-picker-cell-inner-today:after {
      background: white;
    }
  }

  .ant-picker-cell-range-end {
    .ant-picker-cell-inner {
      color: white !important;
    }

    .ant-picker-cell-inner-today:after {
      background: white;
    }
  }

  .ant-picker-cell-in-range:not(.ant-picker-cell-disabled):before,
  .ant-picker-cell-range-start:not(.ant-picker-cell-disabled):before,
  .ant-picker-cell-range-end:not(.ant-picker-cell-disabled):before {
    background: #EEF0FF !important;
  }

  .ant-picker-cell-inner-today {
    position: relative;

    &:after {
      position: absolute;
      bottom: 1px;
      left: 50%;
      margin-left: -1.5px;
      content: '';
      height: 3px;
      width: 3px;
      background: #2563EB;
      border-radius: 50%;
    }
  }
}

.range-picker-date {
  .ant-picker-suffix {
    font-size: 18px !important;
  }
}