@import '@/styles/index';

.cash-management-wrap {
  .main-title {
    display: flex;
    align-items: center;
    height: 57px;
    font-size: 18px;
    border-bottom: 1px solid #F0F0F0;
  }

  .filter-wrap {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;

    .left-wrap {
      .ant-form {
        gap: 10px 0;
      }
    }

    .right-wrap {
      display: flex;
      align-items: center;

      .icon-table {
        color: $primaryColor
      }
    }
  }

  .time-info {
    margin-top: 12px;
    text-align: right;
  }

  .table-wrap {
    margin-top: 12px;

    .empty-table {
      .ant-table-thead {
        .ant-table-cell {
          height: 39px;
        }
      }

      .ant-table-tbody {
        display: none !important;
      }

      .ant-pagination {
        display: none !important;
      }
    }
  }
}