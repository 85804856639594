.tax-rates-edit {
  padding: 24px;
  height: 100vh;

  .header-wrap {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 24px;
      color: rgb(61, 61, 61);
      font-weight: 500;
    }

    .actions {
      display: flex;
      gap: 12px;
    }
  }

  .form-wrap {
    margin-top: 16px;

    .required {
      color: red;
      margin-left: 2px;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }

    .ant-form-item-control-input {
      min-height: 22px;
    }

    .rounding-type-radio-group {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      label {
        flex-basis: calc(50% - 5px);
        padding: 4px 0;
        height: auto;
        border-radius: 6px;
      }

      .rounding-type-radio-item {
        text-align: center;
        line-height: 26px;
      }
    }
  }
}