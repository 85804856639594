@import "/src/styles/mixin";

.employee-create-content{
  background: rgb(249 249 249);
  padding: 24px;
  height: 100vh;

  &-box{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-wrap{
    margin-top: 24px;
    padding: 16px;
    background: rgb(255 255 255);
    border-radius: 8px;
  }

  &-employeeForm,
  &-jobForm{
    margin-top: 12px;
    font-size: 14px;

    .ant-form-item-label{
      width: 150px;
      text-align: left;
      flex: none;
      max-width: none;
    }

    .ant-form-item-control{
      width: 550px;
      flex: none;
      max-width: none;

      .access-code-addon-after{
        cursor: pointer;

        &:hover{
          text-decoration: underline;
        }
      }
    }

    &-tips{
      color: rgb(153 153 153);
      font-size: 14px;
    }

    .ant-select-selection-overflow{
      padding-top: 8px !important;
      padding-bottom: 8px !important;

      .ant-select-selection-item{
        border-radius: 6px;
        padding: 4px 15px;

        // padding-bottom: 4px;
        height: auto;
        border-color: #f4f4f4;
      }
    }
  }
}