$primaryColor: #2563eb;
$shadowColor: rgb(34 34 34 / 15%);

.m-title {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 72px;
  font-size: 18px;
  padding-bottom: 16px;
  .title {
    height: 100%;
    display: flex;
    background-color: #fff;
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
  }
}