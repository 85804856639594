.image-upload-button {
    &-label {
      font-size: 12px;
      line-height: 22px;
      margin-top: 8px;
      color: #666;
    }
}

.ant-upload-list-item-container{
  .ant-upload-list-item.ant-upload-list-item-done{
    padding: 0!important;
    object-fit: cover !important;
    overflow: hidden;
  }

  .ant-upload-list-item::before{
    width: 100%!important;
    height: 100%!important;
  }

  .ant-upload-list-item-thumbnail img{
    object-fit: cover !important;
  }
}
  