.print-out-tab {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 15px;

  .tab-item {
    height: 42px;
    line-height: 26px;
    padding: 8px;
    margin-right: 20px;
  }
}