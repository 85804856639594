.render-space-tags{
  .tag-box {
    display: flex;
    justify-content: space-between;
    .tag-item {
      width: 30%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #FFFFFF;
      border: 1px solid #BEBEBE;
      border-radius: 8px;
    }
  }
}