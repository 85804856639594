.verify-code-wrap{
    width: 100%;
    display: flex;
    align-items: center;

    .verify-code-input{
        box-sizing: border-box;
        position: absolute;
        height: 30px;
        left: -2000px;
        opacity: 0;
        transform: scale(0);
    }

    .verify-code-container{
        display: flex;
        box-sizing: border-box;
        height: 50px;
        width: 100%;

        .character{
            height: 100%;
            flex-grow: 1;
            flex-basis: 0;
            text-align: center;
        }

        .character-default{
            background-color: #fff;
            border-radius: 4px;
            border: 1px solid#e8e8e8;
            color: #222;
            font-weight: 500;
            font-size: 18px;
            cursor: default;
            user-select: none;
            box-sizing: border-box;
            margin-left: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .character-selected-default{
            position: relative;

            &::after{
                content: '';
                position: absolute;
                top: 14px;
                left: 12px;
                width: 1px;
                height: 20px;
                background-color: #ffd100;
                animation: blink 1.5s infinite;
            }
        }

        .character-selected-line{
            border: 1px solid #000;
            outline: none;
        }
    }

    .verification-code-button{
        border: 1px solid#e8e8e8;
        height: 48px;
        padding: 5px 16px;
        text-align: center;
        margin-left: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@keyframes blink {
    0%, 100% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }
}