@import '@/styles/index';

.product-mix-break-down-by-menu {
  margin-top: 16px;
  padding: 16px;
  border-radius: 6px;
  background: white;

  .break-down-by-menu-header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .break-down-by-menu-title-wrap {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      white-space: pre;
    }

    .break-down-by-menu-actions-wrap {
      .icon-table {
        color: $primaryColor;
      }
    }
  }

  .break-down-by-menu-table-wrap {
    margin-top: 16px;

    .empty-table {
      .ant-table-thead {
        .ant-table-cell {
          height: 39px;
        }
      }

      .ant-table-tbody {
        display: none !important;
      }
    }

    .sticky-row {
      position: sticky;
      bottom: 0;
      font-weight: 500;

      td {
        background: #F9F9F9 !important;
      }
    }

    &.no-data {
      .ant-table-expanded-row-fixed {
        margin: 0 -16px !important;
      }
    }

    .ant-table-tbody {
      overflow: auto !important;

      .ant-table-row-level-0 {
        .ant-table-cell-with-append {
          display: flex;
          align-items: center;

          .expand-icon {
            cursor: pointer;
            margin-right: 10px;
          }

          .icon-UpOutlined {
            transform: rotate(90deg);
          }
        }
      }

      .ant-table-row-level-1 {
        .ant-table-cell-with-append {
          padding-left: 58px;

        }

        .ant-table-row-indent {
          display: none;
        }

        .ant-table-row-expand-icon-spaced {
          display: none;
        }

      }
    }

    .ant-table-summary {
      .ant-table-cell {
        padding-top: 26px;
        padding-right: 31px;
        padding-bottom: 26px;
      }
    }
  }
}