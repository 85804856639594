.settleIn {
  .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: rgb(34 34 34);
  }

  .formWrap{
    margin-top: 20px;
  }

  .btnWrap{
    margin-top: 30px;
    text-align: center;
  }
}