.color-filter{
  width: 160px;
  &-area{
    width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &:nth-child(1){
      margin-bottom: 16px;
    }
    &-square{
      width: 40px;
      height: 40px;
      border-radius: 4px;
      margin-bottom: 16px;
      box-sizing: border-box;
      &-default{
        border: 1px solid #BEBEBE;
        position: relative;
        &::before{
          position: absolute;
          content: "";
          width: 45px;
          height: 1px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-45deg);
          border-top: 2px solid #E10013;
        }
      }
    }
    &-square-active{
      border: 2px solid #2563EB;
    }
    &-square:nth-last-child(-n + 3){
      margin-bottom: 0px;
    }
  }
  &-section{
    border-radius: 4px;
    padding: 8px 16px;
    border: 1px solid #bebebe;
    &-inner{
      width: 129px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &-tag{
      border-radius: 4px;
      width: 60px;
      height: 25px;
      &-default{
        border: 1px solid #BEBEBE;
        position: relative;
        &::before{
          position: absolute;
          content: "";
          width: 48px;
          height: 1px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(-20deg);
          border-top: 2px solid #E10013;
        }
      }
    }
  }
}