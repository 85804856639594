.employee-list {
  height: 100%;

  .ant-tabs-content-holder {
    overflow: hidden;
  }

  .ant-tabs-content {
    height: 100%;
  }
}