.rolesWrap {
  .title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: rgb(34 34 34);
  }

  .filterWrap {
    margin-top: 16px;

    .ant-form-item-wrapper {
      margin-right: 16px;
    }
  }

  .tableWrap {
    margin-top: 20px;
  }
}