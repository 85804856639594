.prep-stations{
    position: relative;
    height: 80vh;
    overflow-y: hidden;

    &-wrap{
        padding-top: 24px;
    }
    &-head{
        display: flex;
        align-items: center;    
        cursor: pointer;
        &-title{
            font-weight: 500;
            font-size: 20px;
            line-height: 32px;
            margin-right: 25px;
        }
    }
    &-body{
        margin-right: 16px;
        display: flex;
        &-leftpanel{
            min-width: 311px;
            padding-left: 4px;
            padding-right: 15px;
            height: 100%;
            margin-top: 10px;
        }
        &-rightpanel{
            position: relative;
            padding-left: 18px;
            padding-right: 4px;
            margin-top: 10px;
            flex: 1;
            &::after{
                content: "";
                position: absolute;
                width: 1px;
                height: 100vh;
                background-color: #eaeaea;
                top: 0;
                left: 0;
            }
        }
    }
}
.prep-stations-head-filte-pop{
        padding: 10px 25px 10px 25px;
}