.serviceChargeWrap {
  min-width: 800px;

  .titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 57px;

    border-bottom: 1px solid #F0F0F0;

    .title {
      font-size: 18px;
    }

    .actionsWrap {
      display: flex;
      justify-content: flex-end;
      margin-top: 4px;


      button {
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-btn-icon {
          margin-inline-end: 4px !important;
        }

        .ant-btn-loading-icon {
          margin-inline-end: 10px !important;
        }
      }
    }
  }

  .tableWrap {
    margin-top: 24px;

    .colStatus {
      display: flex;
      align-items: center;

      .greenPoint {
        background-color: #00b578;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        width: 6px;
        height: 6px;
        margin-right: 5px;
      }

      .greyPoint {
        background-color: rgba(0, 0, 0, 0.2);
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        width: 6px;
        height: 6px;
        margin-right: 5px;
      }
    }

    .ant-table-thead {
      tr:first-child {
        & > *:first-child {
          padding-left: 14px;
          border-start-start-radius: 4px !important;
        }

        & > *:last-child {
          border-start-end-radius: 4px !important;
        }
      }

      .ant-table-cell {
        background: #F2F2F2;
        font-weight: 500;
        color: #55565E;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        height: 58px;
        z-index: 1;

        td:first-child {
          padding-left: 14px;
        }
      }

      .ant-table-cell {
        border-color: #DDDDDD;
      }
    }

    .tableRow {
      cursor: pointer;
    }
  }
}

.tableActionsWrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}