.drag-list{
  .drag-box{
    position: relative;
    overflow: hidden;
  }
  .btn-box{
    position: relative;
    right: -120px;
    flex-shrink: 0;
    transition: .3s;
    .right-text{
      white-space: nowrap;
    }
  }
  .drag-item{
    overflow: hidden;
    margin: 4px 0;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 8px;
    padding-left: 10px;
    background-color: #fff;
    transition: .3s;
    .left-info{
      overflow: hidden;
    }
    .text-box{
      padding-right: 10px;
    }
    .text{
      overflow: hidden;
      color: #252525;
      &.s2{
        color: #55565e;
      }
    }
    cursor: pointer;
    &.active, &.active:hover{
      background-color: #EEF0FF;
    }
    &:hover{
      background-color: #f5f5f5;
      .btn-box{
        right: 0;
      }
    }
    .img-box{
      position: relative;
      width: 52px;
      height: 52px;
      flex-shrink: 0;
      img{
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
    .drag-btn{
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 1px;
        height: 24px;
        left: 0;
        top: 50%;
        margin-top: -12px;
        background-color: #ddd;
      }
    }
  }
}