@import '@/styles/index';
.transaction-card {
  position: relative;

  .filter-wrap {
    display: flex;
    justify-content: space-between;
    border-radius: 4px;

    .ant-form {
      gap: 10px 0;

      .anticon-search {
        color: #BEBEBE;
      }
    }

    .right-wrap {
      display: flex;
      align-items: center;

      .icon-table {
        color: $primaryColor
      }
    }
  }

  .table-wrap {
    margin-top: 12px;

    .empty-table {
      .ant-table-thead {
        .ant-table-cell {
          height: 39px;
        }
      }

      .ant-table-tbody {
        display: none !important;
      }

      .ant-pagination {
        display: none !important;
      }
    }
  }

  .action-wrap {
    position: fixed;
    right: 25px;
    top: 80px;
    z-index: 20;
  }
}