@import "../index.scss";
.create-modifier{
  @include menus-background-style;
  @include card-form;
  @include menus-card;
  &-title{
    font-size: 20px;
    margin-left: 15px;
  }
  .usage-card-wrap{
    padding: 0 15px 15px 16px;
  }
}