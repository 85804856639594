:where(.css-dev-only-do-not-override-1wy3e1f).ant-drawer{
    z-index: 1149;
}
.pos-monitor-content-card{
    &-title{
        font-weight: 600;
        font-size: 18px;
    }
    .ant-form-item-required{
        &:before {
            content: none !important;
        }
    }
}
.delete-under-line{
    .ant-drawer-header{
        border-bottom: 0px;
    }
    .ant-checkbox-disabled{
        .ant-checkbox-inner{
            background-color: #93b1f1;
            &::after{
                border-color: #ffffff;
            }
        }
    }
}