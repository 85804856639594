@import "../index.scss";

.menus-usage {
  @include menus-card-style;
  .name {
    margin-bottom: 10px;
  }

  .info {
    display: flex;
    flex-wrap: wrap;
  }
}