.print-out-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin: 6px 0;
  border-bottom: 1px solid #e6e6e6; 
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
}