@import '@/styles/index';
.lossReport {
  .main-title {
    display: flex;
    font-size: 18px;

    &:after {
      content: ' ';
      margin: 0 32px 0 16px;
      display: block;
      height: 28px;
      width: 1px;
      background: #DDD;
    }
  }

  .tabsWrap {
    .ant-tabs-content-holder {
      display: none;
    }
  }

  .timeInfo {
    margin-top: 12px;
    text-align: right;
  }

  .filterWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;

    .leftWrap {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      .employeeSelect {
        .ant-select-selection-placeholder {
          color: #323232;
        }

        .ant-select-arrow {
          color: #5B5B5B;
        }
      }
    }

    .rightWrap {
      display: flex;
      align-items: center;

      .icon-table {
        color: $primaryColor;
      }
    }

  }

  .tableWrap {
    margin-top: 12px;

    .ant-table-column-sort {
      background: #FFF;
    }

    .emptyTable {
      .ant-table-tbody {
        display: none !important;
      }

      .ant-pagination {
        display: none !important;
      }
    }

    .colItemCollapse {
      .ant-collapse-item {
        font-size: 14px;
      }

      .ant-collapse-header {
        padding: 0;
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 0;
          line-height: 22px;
        }
      }
    }
  }

  .emptyTable {
    .ant-table-thead {
      .ant-table-cell {
        height: 39px;
      }
    }

    .ant-table-tbody {
      display: none !important;
    }

    .ant-pagination {
      display: none !important;
    }
  }
}

.columnFilterSelectPopup {
  .ant-select-item {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;

    .ant-checkbox-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .ant-select-item-option-state {
      display: none;
    }
  }

  .ant-select-item-option-selected {
    &:hover {
      background: rgb(0 0 0 / 4%) !important;
    }
  }

  .ant-checkbox-wrapper {
    width: 100%;

    span {
      font-weight: normal;
    }
  }

  .rc-virtual-list-holder {
    max-height: 300px !important;
  }

  .rc-virtual-list-scrollbar-thumb {
    display: none;
  }
}