@import "/src/styles/mixin";

.reset-password{
    width: 350px;

    .mterial-form{
        margin-top: 20px;
        width: 100%;

        .ant-form-item-control-input-content{
            display: flex;
            justify-content: space-between;

            .ant-btn{
                flex: 0 0 48%;
            }
        }

        @include ant-form-item;
        @include reset-btn;
    }
}