@import '@/styles/index';

.top-sub-menus-list {
  padding: 16px;
  border-radius: 6px;
  background: white;

  .top-sub-menus-list-header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-sub-menus-list-title-wrap {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      white-space: pre;
    }

    .top-sub-menus-list-actions-wrap {
      .icon-table {
        color: $primaryColor;
      }
    }
  }

  .top-sub-menus-list-table-wrap {
    margin-top: 16px;

    .ant-table-tbody {
      overflow: auto !important;
    }
  }
}