@import '@/styles/index';
.promotion-wrap {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;

    .title {
      display: flex;
      align-items: center;
      height: 58px;
      font-size: 18px;
    }
  }

  .table-wrap {
    margin-top: 20px;

    .actions-btn {
      display: inline-block;
      width: 32px;
      cursor: pointer;
      color: $primaryColor;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

      &:hover {
        color: var(--primary-hover-color);
      }
    }

    .table-actions-wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .ant-table-column-title{
      z-index: 0;
    }
  }
}