@import '../mixin.scss';
.credit-card-payment-details{
  @include reportSectionGap{
    &-item{
      display: flex;
      justify-content: space-between;
    }
    &-check{
      margin-bottom: 32px;
      &:nth-last-child(1){
        margin-bottom: 0px;
      }
      &-info{
        margin-left: 15px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}