@import '../mixin.scss';
.refund-date{
  @include reportSectionGap{
    &-item{
      margin-top: 10px;
      display: flex;
      &-value{
        margin-left: 5px;
      }
    }
  }
}