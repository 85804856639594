@import "/src/styles/mixin";

.binding-page{
    width: 350px;

    &-title{
        color: rgb(34 34 34);
        display: inline-block;
        vertical-align: middle;
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        margin-bottom: 12px;
    }
}