.product-mix-wrap {
  padding-bottom: 22px;
  min-width: 998px;

  .product-mix-title-wrap {
    position: sticky;
    top: 0;
    z-index: 2;
    height: 72px;
    font-size: 18px;
    padding-bottom: 16px;
    .product-mix-title {
      height: 100%;
      display: flex;
      background-color: #fff;
      align-items: center;
      border-bottom: 1px solid #DDDDDD;
    }
  }

  .content-wrap {
    padding: 22px;
    background: #F5F5F5;
    border-radius: 8px;
    .filter-wrap {
      display: flex;
      justify-content: space-between;

      .filter-right-wrap {
        display: flex;
        gap: 0 10px;

        .ant-btn {
          border: 0;
        }
      }
    }

    .product-mix-split-wrap {
      margin-top: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;
    }
  }
}