.guest-info-section{
  text-align: center;
  &-content{
    display: flex;
    justify-content: center;
    .preview-ui-text-line-new{
      &:nth-child(2){
        margin-left: 15px;
        position: relative;
        &::before{
          position: absolute;
          content: "";
          width: 5px;
          height: 5px;
          background-color: #000000;
          border-radius: 50%;
          left: -10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}