@import "/src/styles/mixin";

.sign-up{
    width: 350px;
    text-align: center;

    &-logo{
        display: inline-block;

        img{
            display: block;
            width: 100%;
            margin-top: 19px;
        }
    }

    .mterial-form{
        margin-top: 40px;
        width: 350px;

        @include ant-form-item;
    }
}