@import '@/styles/index';

.online-orders-pickup-wrap {
  position: relative;

  .action-wrap {
    position: fixed;
    right: 25px;
    top: 80px;
    z-index: 20;

    button + button {
      margin-left: 16px;
    }
  }

  .form-wrap {
    width: 700px;

    .ant-form-item-control-input {
      min-height: auto;
    }

    .ant-radio-wrapper {
      width: fit-content;

      & + .ant-radio-wrapper {
        margin-top: 2px;
      }
    }

    .quote-time-input-wrap {
      margin-left: 20px;
      margin-top: 10px;

      .ant-form-item {
        display: inline-block;
        margin-bottom: 0;

        & + .ant-form-item {
          margin-left: 8px;
        }
      }
    }

    .pickup-hours-form-item {
      .ant-form-item-explain-error {
        padding-left: 382px;
      }

      .pickup-hours-item {
        display: grid;
        grid-template-columns: 150px 200px 300px;
        grid-gap: 16px;
        align-items: center;

        & + .pickup-hours-item {
          margin-top: 8px;
        }
      }
    }


    .pickup-hours-label {
      font-weight: bold;
    }
  }
}

.spin-wrap {
  .ant-spin-blur {
    opacity: 1;
  }
}