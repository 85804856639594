.net-sales-summary-comparison-summary {
  margin-top: 16px;
  padding: 16px;
  border-radius: 6px;
  background: white;

  .comparison-summary-header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .comparison-summary-title-wrap {
      display: flex;
      align-items: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 32px;
      white-space: pre;
    }
  }

  .table-wrap {
    margin-top: 16px;

    .row-wrap {
      position: relative;
      padding-left: 8px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 6px;

      }
    }

    .current-row:after {
      background: #2563EB;
    }

    .comparison-row:after {
      background: #BEBEBE;
    }

    .col-price-wrap {
      .col-price-green {
        color: #35C759;
      }

      .col-price-red {
        color: #E10113;
      }
    }
  }
}