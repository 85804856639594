@import "/src/styles/mixin";

.account-container{
  min-height: 400px;
  height: 100%;
  position: relative;
  padding: 24px;

  &-title{
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    color: rgb(34 34 34);
  }

  &-divider{
    display: flex;
    clear: both;
    width: 100%;
    min-width: 100%;
    margin: 24px 0;
    padding: 0;
    border-top: 1px solid rgb(0 0 0 / 10%);
  }

  &-operate{
    background: rgb(255 255 255);
    border-radius: 8px;
    padding: 32px;
    margin-top: 11px;

    .operate-title{
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: rgb(61 61 61);
    }

    .operate-item{
      display: flex;
      align-items: center;
      line-height: 36px;

      &-label{
        text-align: left;
        color: rgb(0 0 0);
        line-height: 36px;
        width: 100px;
      }

      &-content{
        line-height: 36px;
        display: inline-block;
        color: rgb(51 51 51);
      }

      .ant-btn-link{
        color: #f29d39;
      }
    }
  }
}

.account-model-wrap{
  .ant-modal{
    overflow: hidden;

    .ant-modal-header{
      border-bottom: 0;
    }

    .ant-modal-footer{
      border-top: 0;
    }
  }

  .verify-code-form{
    display: flex;
    align-items: center;

    .verify-code-form-label{
      color: #222;
    }

    .verify-code-form-content{
      flex: 1;
    }
  }
}

.email-subtitle{
  margin-top: 3px;
  font-size: 14px;
  color: rgb(153 153 153);
  font-weight: 400;
}