.breadCrumb {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e6e6e6; 
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  .ant-breadcrumb{
    flex-shrink: 1;
  }
  .slot{
    flex-shrink: 0;
  }
  .ant-breadcrumb {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
  }
  .ant-breadcrumb-link:hover {
    cursor: pointer;
    color: #2563eb; 
    background-color: #fff; 
  }
}