.menus-add-modifier-group {
  position: relative;
  .ant-table-wrapper {
    box-shadow: 2px 10px 10px rgb(77 77 77 / 30%);
    .ant-table-selection{
      display: none;
    }
  }
  .select-table{
    overflow: hidden;
    border-radius: 16px;
    position: absolute;
    z-index: 10;
    max-height: 0;
    transition: .3s;
    left: 0;
    right: 0;
    &.show{
      max-height: 500px;
      box-shadow: 2px 10px 10px rgb(77 77 77 / 30%);
    }
  }
}