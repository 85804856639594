.menuDatabase {
  height: calc(100vh - 84px);
  .top{
    margin-bottom: 15px;
  }
  .search{
    z-index: 2;
    width: 200px;
  }
}
