@import "../index.scss";

.menus-prep-stations {
  @include menus-card-style;
  @include menus-five-items-per-row;

  .ant-checkbox {
    margin-top: 4px !important;
    align-self: flex-start !important;
  }
  .tips-text{
    color: #555;
    cursor: pointer;
    font-style: italic;
  }
}
