@import '../mixin.scss';
.payment-detail{
  @include reportSectionGap{
    &-item{
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
    }
  }
  .item-children{
    margin-left: 15px;
    &-detail{
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      .preview-ui-text-line, .preview-ui-text-line-new{
        &:nth-child(2){
          text-align: right;
        }
      }
    }
  }
}